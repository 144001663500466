import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsStatisticsFilter {
  filterHandlers = {
    update: 'shopStatisticsFilter/updateFilter',
    reset: 'shopStatisticsFilter/resetFilter',
    resetState: 'shopStatisticsFilter/resetFilterState',
    removeSelected: 'shopStatisticsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setPreviewName('date', 'Дата');
    filter.setInitAction('date', 'shopStatistics/initDates');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'dayStart',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
        action: 'shopStatisticsFilter/updateStartDate',
      },
      endDate: {
        key: 'dayEnd',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
        action: 'shopStatisticsFilter/updateEndDate',
      },
    });

    filter.createSelectEntity('serviceType');
    filter.setTitle('serviceType', 'Тип услуги');
    filter.setName('serviceType', 'select_serviceType');
    filter.setPreviewName('serviceType', 'Тип услуги');
    filter.setInitAction('serviceType', 'shopStatistics/initServiceTypeSelect');
    filter.setAction('serviceType', 'shopStatisticsFilter/updateSelect');

    filter.createCheckboxEntity('withAdditional');
    filter.setName('withAdditional', 'checkbox_withAdditional');
    filter.setAction('withAdditional', 'shopStatisticsFilter/updateCheckbox');
    filter.setListElement('withAdditional', {
      id: 'withAdditional',
      name: 'Учитывать доп. смены',
      checked: false,
    });

    this.filterModel = filter.filterModel;
  }
}
