import { Action, Module } from 'vuex-module-decorators';
import store from '@/store';
import FilterModule from '@/store/filter';
import moment from 'moment';
import { filterCheckboxInterface } from '@/lib/layouts/page/filterCheckbox.interface';
import { filterDatePickerInterface } from '@/lib/layouts/page/filterDatePicker.interface';
import { filterSelectInterface } from '@/lib/layouts/page/filterSelect.interface';
import { filterSearchInterface } from '@/lib/layouts/page/filterSearch.interface';

type filterEntityGeneral =
  | filterCheckboxInterface
  | filterDatePickerInterface
  | filterSelectInterface
  | filterSearchInterface;

@Module({ dynamic: true, store, name: 'shopStatisticsFilter', namespaced: true })
export default class ShopStatisticsFilter extends FilterModule {
  constructor(module: ShopStatisticsFilter) {
    super(module);
  }

  @Action({ rawError: true })
  async updateFilter() {
    await this.prepareFilterFromModal();
  }

  @Action({ rawError: true })
  async prepareFilterFromModal() {
    const filter: { string: string; preview: Record<string, { name: string; iterable: boolean; iconClass: string }> } = {
      string: '',
      preview: {},
    };

    const filterModel = this.filterSettings.filterModel;

    this.self.SET_FILTER_INDEX(2);

    for (let i = 0; i < Object.values(filterModel).length; i++) {
      let result: {
        string: string;
        preview: Record<string, { name: string; iterable: boolean; iconClass: string }>;
      } | null = null;
      const filterEntity: filterEntityGeneral = Object.values(filterModel)[i] as filterEntityGeneral;

      if (filterEntity.name.indexOf('checkbox') !== -1) {
        result = await this.self.prepareCheckbox(filterEntity as filterCheckboxInterface);
      }

      if (
        filterEntity.name.indexOf('select') !== -1 &&
        (filterEntity as filterSelectInterface).current.value &&
        (filterEntity as filterSelectInterface).current.id !== '0'
      ) {
        result = await this.self.prepareSelect(filterEntity as filterSelectInterface);
      }

      if (filterEntity.name.indexOf('date') !== -1) {
        result = await this.self.prepareDatePicker(filterEntity as filterDatePickerInterface);
      }

      if (result) {
        await this.self.SET_FILTER_INDEX(this.self.index + 1);

        filter.string += result.string;

        filter.preview = { ...result.preview, ...filter.preview };
      }
    }

    this.self.SET_FILTER(filter.string);
    this.self.SET_FILTER_PREVIEW(filter.preview);

    return filter;
  }

  prepareCheckbox(filterEntity: filterCheckboxInterface) {
    const filter: { string: string; preview: Record<string, { name: string; iterable: boolean; iconClass: string }> } = {
      string: '',
      preview: {},
    };

    if (filterEntity.name.indexOf('checkbox_bool') !== -1) {
      const checkedValue = filterEntity.list[0].checked;

      filter.string = `&${filterEntity.key}=${checkedValue}`;
      filter.preview[filterEntity.key] = {
        name: filterEntity.previewName,
        iterable: false,
        iconClass: filterEntity.iconClass || '',
      };

      return filter;
    }

    const checkedValue = filterEntity.list[0].checked ? 1 : 0;

    if (filterEntity.ignoreNull == true && checkedValue === 0) {
      return filter;
    }

    filter.string = `&${filterEntity.key}=${checkedValue}`;
    filter.preview[filterEntity.key] = {
      name: filterEntity.previewName,
      iterable: false,
      iconClass: filterEntity.iconClass || '',
    };

    return filter;
  }

  @Action({ rawError: true })
  prepareDatePicker(filterEntity: filterDatePickerInterface) {
    const filter: { string: string; preview: Record<string, { name: string; iterable: boolean; iconClass: string }> } = {
      string: '',
      preview: {},
    };

    Object.entries(filterEntity.list).forEach((entry: [string, { value: string; key: string }]) => {
      if (entry[1].value !== '' && entry[1].value !== 'Выбрать') {
        const value = moment(entry[1].value, 'DD/MM/YY').format('YYYY-MM-DD');
        filter.string += `&${entry[1].key}=${value}`;
        filter.preview[filterEntity.key] = {
          name: filterEntity.previewName,
          iterable: false,
          iconClass: filterEntity.iconClass || '',
        };
        this.self.SET_FILTER_INDEX(this.self.index + 1);
      }
    });

    return filter;
  }

  @Action({ rawError: true })
  prepareSelect(filterEntity: filterSelectInterface) {
    const filter: { string: string; preview: Record<string, { name: string; iterable: boolean; iconClass: string }> } = {
      string: '',
      preview: {},
    };
    filter.string += `&${filterEntity.key}=${filterEntity.current.id}`;
    filter.preview[filterEntity.key] = {
      name: filterEntity.previewName,
      iterable: false,
      iconClass: filterEntity.iconClass || '',
    };

    return filter;
  }
}
