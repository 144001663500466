import store from '@/store';
import PageBaseModule from '@/store/page';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { prepareShopStatistics } from '@/lib/Shop';
import { getServiceTypesByStore } from '@/api/templates';
import { getShopStatistics } from '@/api/shop';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import filterModel from './filter';
import ShopsStatisticsFilter from './filterEntity';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import moment from 'moment/moment.js';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'shopStatistics';

interface PreparedShopStatisticsDataInterface {
  closed: {
    id: string;
    percent: number;
    count: number;
    name: string;
    class: string;
  }[];
  statistics: {
    id: string;
    count: number;
    orders: number;
    name: string;
    percent: number;
    class: string;
  }[];
  statisticsTotalCount: number;
  statisticsTotalOrders: number;
  successClosedCount: number;
}

export interface RawShopStatisticsDataInterface {
  need_count: number;
  need_count_count: number;
  success_closed: number;
  success_closed_count: number;
  closed_with_late: number;
  closed_with_late_count: number;
  need_opened: number;
  need_opened_count: number;
  did_not_go_out: number;
  did_not_go_out_count: number;
  fast_replacement: number;
  fast_replacement_count: number;
  fake_call: number;
  fake_call_count: number;
  opened_due_to_absence: number;
  opened_due_to_absence_count: number;
  by_system: number;
  by_employee: number;
  by_admin: number;
  by_partner: number;
  by_system_fast_replacement: number;
  by_employee_fast_replacement: number;
  by_admin_fast_replacement: number;
  by_partner_fast_replacement: number;
}

export interface ChartDataItem {
  id: string;
  count: number;
  orders?: number;
  name: string;
  percent: number;
  class: string;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ShopStatisticsModule extends PageBaseModule {
  shopId = '0';
  statistics: ChartDataItem[] = [];
  statisticsTotalCount = 0;
  statisticsTotalOrders = 0;
  successClosed = 0;
  closed: ChartDataItem[] = [];
  filter: Filter;
  defaultData = {
    by_admin: 0,
    by_admin_fast_replacement: 0,
    by_employee: 0,
    by_employee_fast_replacement: 0,
    by_system: 0,
    by_system_fast_replacement: 0,
    by_partner: 0,
    by_partner_fast_replacement: 0,
    closed_with_late: 0,
    closed_with_late_count: 0,
    did_not_go_out: 0,
    did_not_go_out_count: 0,
    fake_call: 0,
    fake_call_count: 0,
    fast_replacement: 0,
    fast_replacement_count: 0,
    need_count: 0,
    need_count_count: 0,
    need_opened: 0,
    need_opened_count: 0,
    opened_due_to_absence: 0,
    opened_due_to_absence_count: 0,
    success_closed: 0,
    success_closed_count: 0,
  };

  constructor(module: ShopStatisticsModule) {
    super(module);

    const filter = new filterModel();
    this.filter = getModule(ShopsStatisticsFilter);
    this.filter.resetFilter();
    this.filter.setFilterName('shopStatisticsFilter');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_STATISTICS(params: PreparedShopStatisticsDataInterface) {
    this.successClosed = params.successClosedCount;
    this.closed = params.closed;
    this.statistics = params.statistics;
    this.statisticsTotalCount = params.statisticsTotalCount;
    this.statisticsTotalOrders = params.statisticsTotalOrders;
  }

  @Mutation
  SET_SHOP(shopId: string) {
    this.shopId = shopId;
  }

  @Action({ rawError: true })
  initDates() {
    const startDate = moment().add(-29, 'days').format('DD/MM/YY').split('/');

    const today = moment().format('DD/MM/YY').split('/');

    return {
      startDate: { day: startDate[0], month: startDate[1], year: startDate[2] },
      endDate: { day: today[0], month: today[1], year: today[2] },
    };
  }

  @Action({ rawError: true })
  async initServiceTypeSelect() {
    try {
      const serviceTypes = await getServiceTypesByStore(this.shopId);

      return serviceTypes.filter(({ count }) => count).map(({ id, name }) => ({ id, value: name }));
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async getStatistics() {
    try {
      const filter = this.filter.filterSettings.filter;

      if (filter.indexOf('dayStart') !== -1 || filter.indexOf('dayEnd') !== -1) {
        const result = await getShopStatistics(this.shopId, filter);

        this.updateData(result);
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  updateData(result: RawShopStatisticsDataInterface = this.defaultData) {
    const preparedData = prepareShopStatistics(result) as PreparedShopStatisticsDataInterface;

    this.SET_STATISTICS(preparedData);
  }

  @Action({ rawError: true })
  async init(shopId: string) {
    this.SET_SHOP(shopId);
    await this.filter.init();
    await this.filter.updateFilter();
    await this.getStatistics();
  }
}

export default getModule(ShopStatisticsModule);
