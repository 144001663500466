
import { Component, Vue } from '@/lib/decorator';

import ShopEntityModule from '@/store/shops/entity';
import ShopStatisticEntityModule, { ChartDataItem } from '@/store/shops/statistics/index';
import FilterInstant from '@/components/FilterInstant.vue';
import TableList from '@/components/data/TableList.vue';
import moment from 'moment';
import EChart from '@/components/EChart.vue';
import { getPlural } from '@/lib/Utils';
import closedChartOptions, { init as initClosedOption } from './ClosedChartOptions';
import statisticChartOptions, { init as initStatisticsOption } from './StatisticChartOptions';
import { FilterModel } from '@/lib/layouts/page/filter.interface';
import TitleTab from '../_titleTab.vue';
import UserModule from '@/store/user';

const scssVariables = {
  chartColorGreen: '#74D695',
  chartColorPurple: '#AD90D3',
  chartColorRed: '#E27F7F',
  chartColorDarkPurple: '#515184',
  chartColorBlue: '#5B93CC',
  chartColorDarkBlue: '#5870A0',
  chartColorYellow: '#E9C56A',
};

@Component({
  components: {
    EChart,
    TableList,
    FilterInstant,
    TitleTab,
  },
})
export default class ShopStatistic extends Vue {
  shopId = '';
  initStatisticsOption = initStatisticsOption;
  initClosedOption = initClosedOption;
  statisticChartOptions = statisticChartOptions;
  closedChartOptions = closedChartOptions;

  statisticsAdditional = {
    successClosed: scssVariables.chartColorGreen,
    closedWithLate: scssVariables.chartColorPurple,
    needOpened: scssVariables.chartColorRed,
    fakeCall: scssVariables.chartColorYellow,
  };

  closedAdditional = {
    bySystem: scssVariables.chartColorDarkPurple,
    byEmployee: scssVariables.chartColorDarkBlue,
    byAdmin: scssVariables.chartColorBlue,
  };

  colsClosed = [{ value: 'percent' }, { value: 'name', width: '100%' }, { value: 'count' }];
  colsVacancies = [{ value: 'percent' }, { value: 'name', width: '100%' }, { value: 'count' }, { value: 'orders' }];

  get isShopOwner(): boolean {
    return ShopEntityModule.isShopOwner;
  }

  get isSupervisor(): boolean {
    return UserModule.isSupervisor;
  }

  get currentTab(): string {
    return ShopEntityModule.tabsNav.statistics?.id;
  }

  get model(): FilterModel {
    return ShopStatisticEntityModule.filter.filterSettings.filterModel;
  }

  get startDate(): string {
    return (ShopStatisticEntityModule.filter.filterSettings.filterModel.date.list as any)?.startDate.value;
  }

  get endDate(): string {
    return (ShopStatisticEntityModule.filter.filterSettings.filterModel.date.list as any)?.endDate.value;
  }

  get period(): number {
    const start = moment(this.startDate, 'DD/MM/YY');
    const end = moment(this.endDate, 'DD/MM/YY');
    const duration = moment.duration(end.diff(start));

    return duration.asDays() + 1;
  }

  get declOfNumWordDay(): string {
    return getPlural(this.period, ['день', 'дня', 'дней']);
  }

  get statisticsTotalCount(): number {
    return ShopStatisticEntityModule.statisticsTotalCount;
  }

  get statisticsTotalOrders(): number {
    return ShopStatisticEntityModule.statisticsTotalOrders;
  }

  get successClosed(): number {
    return ShopStatisticEntityModule.successClosed;
  }

  get statistics(): ChartDataItem[] {
    return ShopStatisticEntityModule.statistics;
  }

  get closed(): ChartDataItem[] {
    return ShopStatisticEntityModule.closed;
  }

  get statisticsChart() {
    return this.mergeChartWithData({
      data: this.statistics,
      template: this.statisticChartOptions,
      additional: this.statisticsAdditional,
    });
  }

  get closedChart() {
    return this.mergeChartWithData({
      data: this.closed,
      template: this.closedChartOptions,
      additional: this.closedAdditional,
    });
  }

  mergeChartWithData(param: { data: ChartDataItem[]; template: any; additional: any }) {
    let chartData = param.data
      .filter((el: ChartDataItem) => el.percent || el.percent === 0)
      .map((el: ChartDataItem) => {
        return {
          value: el.percent,
          name: el.name,
          label: {
            color: param.additional[el.id],
          },
          itemStyle: {
            color: param.additional[el.id],
          },
        };
      });

    param.template.series.forEach((el: any) => {
      el.data = chartData;
    });

    return param.template;
  }

  getCellAttrs() {
    let group: any;

    return function (props: any) {
      if (props.row.group === group) {
        return {};
      }

      group = props.row.group;

      return {
        class: ['cp-table-list-cell--divider', 'cp-table-list-cell--pt-8'],
      };
    };
  }

  async updateList(): Promise<void> {
    await ShopStatisticEntityModule.setPageToBegin();
    await ShopStatisticEntityModule.filter.updateFilter();
    await ShopStatisticEntityModule.getStatistics();
  }

  beforeMount(): void {
    this.shopId = this.$route.params.shopId as string;
  }

  mounted(): void {
    ShopEntityModule.initTitle(this.shopId);
    ShopStatisticEntityModule.init(this.shopId);
  }

  beforeUpdate(): void {
    if (!this.isShopOwner && !this.isSupervisor) {
      this.$router.push({ name: 'shops' });
    }
  }
}
